// header{
//   .logo-block{
//     font-size: 30px;
//     cursor: pointer;
//     padding: 8px 20px 0px;
//     display: inline-block;
//     text-align: center;
//   }
//
// }



#user-login>div{
  width: 100%;
}

#user-login{
  max-width: 600px;
  margin: 160px auto 0px;
  background: #f5f5f5;
  padding: 30px;
  display: flex;
  input[type="text"], input[type="password"]{
    border: solid 0px #bfbfbf;
    height: 45px;
    width: 100%;
    outline: none;
    text-indent: 10px;
  }
  label {
    display: inline-block;
    margin-bottom: 0rem;
  }

  input[type="submit"]{
    width: 150px;
    padding: 15px;
    background: #003087;
    color: #fff;
    outline: none;
    height: 45px;
  }

  .form-item .description {
    font-size: 0.85em;
        color: #969696;
        margin-top: 5px;
        margin-bottom: 20px;
  }
}
