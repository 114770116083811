body {
	font-family: 'Ktf-Regular', sans-serif!important;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	line-height: 1.3;
}
body, html {
	height: 100%
}

h1{
  font-family: 'Ktf-Bold', sans-serif;
}

h2{
  font-family: 'ktf-Semibold', sans-serif;
}

h3{
  font-family: 'Kaff-Medium', sans-serif;
}

h4{
  font-family: 'Ktf-Regular', sans-serif;
}

h5{
  font-family: 'Ktf-Regular', sans-serif;
}

h6{
  font-family: 'Ktf-Regular', sans-serif;
}

p{
  font-family: 'Ktf-Regular', sans-serif;
}



@media (min-width: 1px){
	.coming-soon{
		max-width: 280px;
		position: relative;
		margin: 70px auto 60px;
		min-height: 340px;
		z-index: -1;
	.object {
	  position: absolute;
	  animation: sway 2.4s infinite;
	  animation-timing-function: ease-in-out;
	  -webkit-transform-origin: top;
	  -moz-transform-origin: top;
	  transform-origin: top;
	  left: 0;
	  right: 0;
	  height: 5%;
	  z-index: 999;
	  text-transform: uppercase;
	}

	.object-shape {
		width: 100%;
		height: 280px;
		border-radius: 50%;
		display: flex;
		background-color: #00b0f7;
		margin: 0 auto;
		align-items: center;
		position: relative;
		padding: 0px 30px;
		color: #fff;
		text-align: center;
		font-weight: 900;
		box-sizing: border-box;
		font-size: 40px;
		border: solid 10px #b1b1b1;
	}

	.object-shape span {
	  font-size: 22px;
	  color:white;
	}

	.object-rope {
		height: 100px;
		width: 20px;
		background-color: #b1b1b1;
		content: "";
		display: block;
		margin-left: 46%;
	}
	}
}

@media (min-width: 992px){
	.coming-soon{
		max-width: 280px;
		position: relative;
		margin: 70px auto 60px;
		min-height: 340px;
	z-index: -1;
		@keyframes sway {
	  0% {
	    transform: rotate(8deg);
	  }
	  50% {
	    transform: rotate(-8deg);
	  }
	  100% {
	    transform: rotate(8deg);
	  }
	}
	.object {
	  position: absolute;
	  animation: sway 2.4s infinite;
	  animation-timing-function: ease-in-out;
	  -webkit-transform-origin: top;
	  -moz-transform-origin: top;
	  transform-origin: top;
	  left: 0;
	  right: 0;
	  height: 5%;
	  z-index: 999;
	  text-transform: uppercase;
	}

	.object-shape {
		width: 100%;
		height: 280px;
		border-radius: 50%;
		display: flex;
		background-color: #00b0f7;
		margin: 0 auto;
		align-items: center;
		position: relative;
		padding: 0px 30px;
		color: #fff;
		text-align: center;
		font-weight: 900;
		box-sizing: border-box;
		font-size: 40px;
		border: solid 10px #b1b1b1;
	}

	.object-shape span {
	  font-size: 22px;
	  color:white;
	}

	.object-rope {
		height: 100px;
		width: 20px;
		background-color: #b1b1b1;
		content: "";
		display: block;
		margin-left: 46%;
	}
	}
}


.blue{
  position:relative;
  vertical-align: middle;
  text-align: center;
  transition: 0.5s;
  padding: 0 20px;
  cursor: pointer;
  border: 1px solid #1f379c;
  -webkit-transition:0.5s;
	border-radius: 40px;
}

.blue:hover {
  border: 1px solid rgba(0,160,80,0);
  color: #FFF;
	text-decoration: none;
	border-radius: 40px;
}

.blue::before, .blue::after {
  width: 100%;
  height:100%;
  z-index: 3;
  content:'';
  position: absolute;
  top:0;
  left:0;
  box-sizing: border-box;
  transform: scale(0);
  transition: 0.5s;
	border-radius: 40px;
}

.form-submit::before {
  border-bottom: 1px solid #FFF;
  border-left: 1px solid #FFF;
  transform-origin: 0 100%;
	border-radius: 40px;
}

.form-submit::after {
  border-top: 1px solid #FFF;
  border-right: 1px solid #FFF;
  transform-origin: 100% 0%;
	border-radius: 40px;
}
//
// .form-submit::before {
//   border-bottom: 1px solid #FFF;
//   border-left: 1px solid #FFF;
//   transform-origin: 0 100%;
// }
//
// .form-submit::after {
//   border-top: 1px solid #FFF;
//   border-right: 1px solid #FFF;
//   transform-origin: 100% 0%;
// }

.form-submit:hover::after, .form-submit:hover::before {
  -webkit-transform: scale(1);
	border-radius: 40px;
}

.form-submit::before {
  border-bottom: 1px solid #FFF;
  border-left: 1px solid #FFF;
	border-radius: 40px;
  -webkit-transform-origin: 100% 0%;
}

.form-submit::after {
  border-top: 1px solid #FFF;
  border-right: 1px solid #FFF;
	border-radius: 40px;
  -webkit-transform-origin: 0% 100%;
}

.curmudgeon::before {
  border-bottom: 3px solid #FFF;
  border-left: 0;
  -webkit-transform-origin: 0% 100%;
}


.i18n-en{
	.arabic-content{display: none;}
}

.i18n-ar{
	.english-content{display: none;}
}






html {
  font-size: 16px;
}

// @include media-breakpoint-up(sm) {
//   html {
//     font-size: 14px;
//   }
// }

@include media-breakpoint-up(md) {
  html {
    font-size: 18px;
  }
}

input[type="radio"], input[type="checkbox"]{outline: none;}

.i18n-ar{
	.right-menu ul li {
	    font-size: 15px!important;
	}

	.management-team .textual_wrapper .listview_item h6, .management-team .textual_wrapper .listview_item .h6 {
    font-size: 15px!important;
	}
		footer p {
	    font-size: 15px!important;
		}

}
