.login-modal{
  .login-modal{
    padding: 4px 4px 30px;
    .nav-item {
    margin-bottom: -1px;
    width: 50%;
    .nav-link {
      border: 1px solid transparent;
      border-top-right-radius: 0rem;
      border-top-left-radius: 0rem;
      text-transform: uppercase;
      text-align: center;
    }
    .nav-link.active, .show .nav-link {
    color: #151515;
    background-color: #baebff;
    border-color: #ddd #ddd #fff;
    }
   }
  }
}
.signup-modal{
  .login-modal{
    padding: 50px 50px;
    }
  }


.modal-dialog{
.modal-content {
    border-radius: 0px;
    .login-modal{
      color: #616161;
      h5{
        text-transform: uppercase;
        text-align: center;
        font-size: 16px;
        margin-top: 40px;
      }
      button{
        padding: 10px;
        width: 100%;
        margin-top: 20px;
        height: 40px;
        text-transform: uppercase;
        font-size: 14px;
      }
      .login{
            padding: 0px 40px;
        button{
        background: #11b4e5;
        color: #fff;
        border: 0px;
        margin-top: 30px;
        }
      }
      .one-click-sign{
            padding: 0px 40px;
        .facebook-log, .gplus-log{
          button{
          width: 100%;
          padding: 5px;
          text-align: center;
          margin-top: 20px;
          }
        }
        .facebook-log{
          button{
          background: #335896;
          color: #fff;
          font-size: 22px;
          }
        }

        .gplus-log{
          button{
          border: solid 2px #f5f5f5;
          background: url(../../assets/icons/google-icon.png) no-repeat center;
          }
          }
        button{
          background: #fff;
          border: solid 1px #777;
        }

        h6{
            text-transform: uppercase;
            text-align: center;
            font-size: 14px;
            margin-top: 50px;
            margin-bottom: 0px;
          }

      }
      input[type="mail"], input[type="password"]{
        line-height: inherit;
        width: 100%;
        padding: 14px;
        margin-top: 20px;
        background: #f5f5f5;
        border: solid 1px #f5f5f5;
        text-transform: uppercase;
        font-size: 14px;
        color: #333;
        height: 40px;
      }
    }
    .modal-header {
      padding: 0px 15px 0px;
      border-bottom: 0px solid #eceeef;
    }
    .forgot-password{
      text-align: right;
      span{
        font-size: 13px;
        color: #1eade8;
      }
    }
    .close{
      position: absolute;
      right: -18px;
      top: 0px;
      height: 18px;
      background: #d2d2d2;
      opacity: 1;
      color: #5d5d5d;
      line-height: 15px;
      font-size: 16px;
      padding: 0px 3px;
    }

  }
}
