@media (min-width:1px){

.aboutus-ourstory{padding-left: 0px; padding-right: 0px; height: 100vh;}
.site-overview{
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;

    picture{
      position: relative;
      width: 100%;
      display: inline-block;
      height: 100%;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        // transform-origin: 50% 50%;
        // transform: translate3d(0px, 0%, 0px);
      }
    &::after {
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 0;
      right: 0px;
      background-color: rgba(0, 0, 0, 0.4);
      }
    }


  .textual {
    flex-direction: column;
        justify-content: flex-end;
        z-index: 1;
        display: flex;
        position: absolute;
        color: #fff;
        // background: rgba(30, 75, 177, 0.8);
        padding: 1px 15px;
        text-shadow: 1px 1px 2px #252525;
    h2{
      font-size: 1.4rem;
      width: 100%;
    }
    h4, .h4 {
    color: #fff;
    font-size: 1.2rem;
    }
  }

.our-story, .vision, .mission, .values{height: 100%;}

  .content-explained{
    display: none;
  }
  // .explore{display: block!important;}
}
.textual-aboutus-story {
  p{margin-bottom: 0px;}
}

.i18n-en .site-overview{

  .layout-one, .layout-two, .layout-three, .layout-four, .layout-five{
    top: 10%;
    left: 1%;
  }
}

.i18n-ar .site-overview{

  .textual {
    text-align: right;
    display: flex;
    align-items: right;
  }

  .layout-one{
    top: 15%;
    right: 1%;
  }
  .layout-two{
    top: 15%;
    right: 1%;
  }
  .layout-three{
    top: 15%;
    right: 1%;
  }
  .layout-four{
    top: 15%;
    right: 1%;
  }
  .layout-five{
    top: 15%;
    left: 1%;
  }
}

/*Inner page banners*/
.inner-banner{
  width: 100%;
  max-width: 6000px !important;
  margin: auto;
  position: relative;
  height: 100%!important;
  picture{
    position: relative;
    width: 100%;
    display: inline-block;
    height: 100%;
    img{width: 100%; height: 100%; object-fit: cover;}
  &::after {
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.3);
    }
  }
  .banner-image{
    position: relative;
    overflow: hidden;
    height: 100vh;
  }

  .banner-caption{
    position: absolute;
    top: 0%;
    width: 100%;
    text-align: center;
    padding: 0 5%;
    color: #fff;
    left: 0px;
    right: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .about-banner-caption{
    width: 100%;
    h2, .h2 {
      font-size: 1.4rem;
    }
    h4, .h4 {
    color: #fff;
    font-size: 1rem;
    }
  }
  }

  .newsletter-section{
      background: linear-gradient(to right, #0098b1, #004d6b);
      width: 100%;
      height: 100%;
      color: #fff;
      .news-letter {
      text-align: center;
      margin: 0px auto;
      max-width: 750px;
      width: 100%;
      height: 180px;
      height: 100%;
    }
  }





  html {
    height: 100%;
  }
  body.page-node-17 {
    position: relative;
    margin: 0;
    height: 100%;
  }

  .main-wrapper {
    height: 100% !important;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
  }

  .onepage-wrapper {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    padding: 0;
    -webkit-transform-style: preserve-3d;
  }

  .onepage-wrapper .ops-section {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .onepage-pagination {
    position: absolute;
    right: 10px;
    top: 50%;
    z-index: 5;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .onepage-pagination li {
    padding: 0;
    text-align: center;
  }
  .onepage-pagination li a{
    padding: 10px;
    width: 4px;
    height: 4px;
    display: block;

  }
  .onepage-pagination li a:before{
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    background: rgba(0,0,0,0.85);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
  }

  .onepage-pagination li a.active:before{
    width: 10px;
    height: 10px;
    background: none;
    border: 1px solid black;
    margin-top: -4px;
    left: 8px;
  }

  .logo-wrapper-bg{
    position: absolute;
    margin: 0px;
    bottom: 0px;
    width: 100%;
    justify-content: center;
    background: Rgba(255, 255, 255, 0.8);
    .logo-content2{
      padding: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      max-width: 240px;
      .logo{
        // width: 90px;
        // height: 90px;
        // border-radius: 50%;
        // margin: 0px 0px;
        // overflow: hidden;
        // background: #fff;
        img{
          max-width: 220px;
          max-height: 100px;
        }
      }
      // p {
      //   margin-top: 10px;
      //   margin-bottom: 0rem;
      //   }
    }
    .logo-content3{
      padding: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      max-width: 240px;
      .logo{
        // width: 90px;
        // height: 90px;
        // border-radius: 50%;
        // margin: 0px 0px;
        // overflow: hidden;
        // background: #fff;
        img{
          max-width: 220px;
          max-height: 100px;
        }
      }
      // p {
      //   margin-top: 10px;
      //   margin-bottom: 0rem;
      //   }
    }
      // a{color: #fff;}
      //
      // .mobile{
      //   .info{
      //     height: 100%;
      //     display: flex;
      //     align-items: center;
      //     max-width: 120px;
      //     h6{text-align: left;}
      //   }
      // }
  }

  // .logo-wrapper-bg .mobile .carousel-inner {
  //     height: 140px;
  // }
  .industry-about{
    padding: 0px 20px;
  }

.category-section{
  .mobile{
    height: 140px;
    .swiper-slide {
        align-items: start!important;
    }
  }
}
.category-inner-section{
  .web{
    height: 140px;
    }
  }

}




@media (min-width:768px){

.aboutus-ourstory{height: 100vh;}
// .slide-left{
//    opacity: 0;
//    transition: all 1s linear;
//    transform: translate3d(0px, 520px, 0px);
//  }

// .onepage-wrapper .ops-section.active{
// .slide-left{
//    opacity: 1;
//    transform: translate3d(0px, 0px, 0px);
//  }
// }

.about-banner-caption{
  width: 100%;
  h2, .h2 {
    font-size: 2.4rem!important;
  }
  h4, .h4 {
  color: #fff;
  font-size: 1.4rem!important;
  }
}

.section, .fp-tableCell{
      height: 100% !important;
      background: #003087;
}

  .section .textual-content {
     opacity: 0;
     transition: all 1s linear;
     transform: translate3d(0px, 520px, 0px);
   }

   .section.active{
    .textual-content{
      opacity: 1;
      transform: translate3d(0px, 0px, 0px);
    }
   }

.site-overview{
  position: relative;
  height: 100vh;
  overflow: hidden;
  width: 100%;

  .textual {
    flex-direction: column;
        justify-content: flex-end;
        z-index: 1;
        display: flex;
        align-items: left;
        position: absolute;
        color: #fff;
        // background: rgba(30, 75, 177, 0.8);
        padding: 40px!important;
        text-shadow: 1px 1px 2px #252525;
    h2{
      font-size: 2.6rem;
    }
    h4, .h4 {
    color: #ffe13c;
    font-size: 1.6rem;
    }

    .textual-content{
      h2, .h2 {
        font-size: 1.2rem;
      }

      h4, .h4 {
        font-size: 1rem;
      }
    }
  }

  .content-explained{
    display: none;
    font-size: 20px;
  }
  // .explore{display: block!important;}
}

/*Inner page banners*/
.inner-banner{
  width: 100%;
  max-width: 6000px !important;
  margin: auto;
  picture{
    position: relative;
    width: 100%;
    display: inline-block;
    height: 100vh;
    img{width: 100%; height: 100%;}
  &::after {
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.3);
    }
  }
  .banner-image{
    position: relative;
    overflow: hidden;
    height: 100vh;
  }

  .banner-caption{
    padding: 0 20%!important;
  }
}

.newsletter-section{
    background: linear-gradient(to right, #0098b1, #004d6b);
    width: 100%;
    height: 100%;
    color: #fff;
    .news-letter {
    text-align: center;
    margin: 40px auto!important;
    max-width: 750px;
    width: 100%;
    height: 180px;
    height: 100%;
  }
}





html {
  height: 100%;
}
body.page-node-17 {
  position: relative;
  margin: 0;
  height: 100%;
}

.wrapper {
  height: 100% !important;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.onepage-wrapper {
  width: 100%;
  height: 100vh;
  display: block;
  position: relative;
  padding: 0;
  -webkit-transform-style: preserve-3d;
}

.onepage-wrapper .ops-section {
  width: 100%;
  height: 100vh;
  position: relative;
}

.onepage-pagination {
  position: absolute;
  right: 10px;
  top: 50%;
  z-index: 5;
  list-style: none;
  margin: 0;
  padding: 0;
}
.onepage-pagination li {
  padding: 0;
  text-align: center;
}
.onepage-pagination li a{
  padding: 10px;
  width: 4px;
  height: 4px;
  display: block;

}
.onepage-pagination li a:before{
  content: '';
  position: absolute;
  width: 4px;
  height: 4px;
  background: rgba(0,0,0,0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}

.onepage-pagination li a.active:before{
  width: 10px;
  height: 10px;
  background: none;
  border: 1px solid black;
  margin-top: -4px;
  left: 8px;
}

.textual-content{
  h2, .h2 {
    font-size: 2.4rem!important;
  }
  h4, .h4 {
  color: #fff;
  font-size: 1.4rem!important;
  }
}

.industry-about{
  font-size: 24px;
  line-height: 40px;
}

.logo-wrapper-bg{
  .logo-content2{
    .logo{
      img{
        max-width: 220px !important;
        max-height: 130px !important;
      }
    }
    p {
      margin-top: 10px;
      margin-bottom: 0rem;
      }
    a{color: #fff;}

     .info{width: 120px;}
  }
  //
  .box-anim1 .info{text-align: right; padding-right: 5px;}
  .box-anim2 .info{text-align: left; padding-left: 5px;}
}

}


@media (min-width: 1px) and (max-width: 320px) {

  .inner-banner .banner-image {
    height: 100vh;
  }

  .site-overview {
    height: 100vh;
  }
}


@media (min-width: 1px) and (max-width: 768px) {
.menu{
  padding: 30px 0px 70px;
    max-height: 100%;
    overflow-y: scroll;
  }

  .right-menu .menu{padding: 10px 0px 10px;}

.site-overview .content-explained{
  p {
    margin-top: 0;
    margin-bottom: .6rem;
  }
}

}

@media (min-width:1024px){
  .industry-about{
    max-width: 80%;
    // padding: 0px 200px!important;
  }
  .layout-one{
    top: 20%!important;
    width: 55%;
  }
  .layout-two{
    top: 20%!important;
    width: 55%;
  }
  .layout-three{
    top: 20%!important;
    width: 55%;
  }
  .layout-four{
    top: 20%!important;
    width: 55%;
  }
  .layout-five{
    bottom: 0px;
    top: 20%!important;
    width: 55%;
  }

  .i18n-en{
    .layout-one{
      left: 15%!important;
    }
    .layout-two{
      left: 15%!important;
    }
    .layout-three{
      left: 15%!important;
    }
    .layout-four{
      left: 40%!important;
    }
    .layout-five{
      left: 15%!important;
    }
  }

  .i18n-ar {
    .layout-one{
      right: 15%!important;
    }
    .layout-two{
      right: 15%!important;
    }
    .layout-three{
      right: 15%!important;
    }
    .layout-four{
      right: 15%!important;
    }
    .layout-five{
      right: 15%!important;
    }
    ul{list-style-type: none;}
    .explain-btn{float: right;}
  }

}


.i18n-ar .onepage-wrapper .ops-section.active .slide-left{
  text-align: right;
}

.about-banner-caption{
    max-width: 780px;
    width: 100%;
}

.fp-tableCell{ height: 120px !important;}

// .minplus{background: url(../../assets/icons/minus-icon.png) no-repeat center!important;}
// .abtadMinus{background: url(../../assets/icons/plus-icon.png) no-repeat center;}
