@media (min-width:1px){
  .news-media-wrapper {
    background-color: #003087;
    .news-media {
      position: relative;
      color: #fff;
      padding: 90px 5%;
      .news-item{
        padding-bottom: 20px;
        border-bottom: solid 1px #5482d4;
        margin-bottom: 30px;
        img{width: 100%;}
        h5{
          a{color: #fff;}
        }
      }
    }
    .more-button{
      color: #fff;
      position: relative;
    .fa-long-arrow-right{
      border: solid 1px #9e9e9e;
      border-radius: 40px;
      padding: 2px 3px;
      font-size: 14px;
      width: 20px;
      height: 20px;
      color: #bbb;
      margin-left: 8px;
      }
    }
    .date{
      margin-top: 6px;
      color: rgba(255, 255, 255, .8);
    }
  }

  .news-detailed{
    background-color: #003087;
    color: #fff;
    padding: 85px 5% 20px;
    #breadcrumb{
      margin-bottom: 45px;
      font-size: 18px;
      color: #fff;
      a{
        color: #fff;
        font-size: 18px;
      }
    }
    .date{color: #c1d7ff;}
    .field-img{
      padding: 10px 0px;
      img{
      width: 100%;
      }
    }
    h2, .h2 {
    font-size: 1.4rem;
    }
    .description{
      padding: 20px 0px;
      a{color: #fff;}
    }
  }
  .i18n-en{
    .news-media-wrapper .date{
      float: right;
    }
  }
  .i18n-ar{
    .news-media{
      text-align: right;
    }
    .news-media-wrapper .date{
      float: left;
    }
    .news-media-wrapper .more-button .fa-long-arrow-right{transform: scaleX(-1); margin-right: 10px;}
  }
  .see-more-news{
    border: solid 1px #81aeff;
    padding: 10px 10px 7px;
    border-radius: 4px;
    a{color: #fff;}
  }

  .pager-load-more a{
    border: solid 1px #81aeff;
    padding: 10px 10px 7px;
    border-radius: 4px;
    color: #fff;
  }


}


@media (min-width:992px){
  .news-media-wrapper{
    .news-media{
      padding: 90px 10%!important;
      .news-item{
        margin: 30px 0px 60px!important;
        padding-bottom: 60px!important;
      }
    }
  }

  .news-detailed{
    padding: 90px 20% 30px !important;
    h2, .h2 {
    font-size: 2rem!important;
    }
    .field-img{
      padding: 10px 0px;
    }
  }
}
