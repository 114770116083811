@media (min-width:1px){
  .careers-wrapper{
    position: relative;
    background-color: #003087;
    padding-top: 60px;
    color: #fff;
    .banner-image{
      max-height: 350px;
      overflow: hidden;
      picture{height: auto;}
    }
    .textual{
      padding: 4% 15px;
      margin: auto;
    }

    .webform-component-textfield, .webform-component-number, .webform-component-email, .webform-component-select{
      width: 100%;
      border: solid 1px #fff;
      position: relative;
      height: 46px;
      border-radius: 4px;
      margin-top: 0px;
      background: #fff;
      label{
        position: absolute;
        top: .7em;
        padding: 5px 5px 5px 15px;
        letter-spacing: 1px;
        color: #333;
        transition: ease .3s;
        font-weight: normal;
        font-size: 14px;
        margin-bottom: 0px;
        text-transform: uppercase;
      }
      .label-active {
        top: .1em;
        font-size: 11px;
        color: #333;
        padding: 5px 5px 5px 10px;
      }
      input[type="text"], input[type="email"]{
        background: transparent;
        border: solid 0px #ccc;
        width: 100%;
        color: #333;
        text-indent: 10px;
        margin-top: 20px;
        outline: none;
        box-shadow: 0px 0px 0px;
      }
      input[type="text"], input[type="email"], input[type="password"]{
        line-height: inherit;
        width: 100%;
        padding: 0px 0px;
        margin-top: 15px;
        text-transform: inherit;
        font-size: 16px;
        color: #333;
        height: 30px;
        outline: none;
        box-shadow: 0px 0px 0px;
        outline: none;
      }

      select{
        // background: url(/sites/all/themes/fh/assets/icons/down-arrow.png) no-repeat;
        border: solid 0px #f5f5f5;
        text-transform: uppercase;
        font-size: 14px;
        filter: brightness(-1);
        color: #333;
        height: 42px;
        width: 100%;
        border-radius: 0px;
        -webkit-appearance: none;
        -moz-appearance: none;
        padding:  1px 2px 1px 10px;
        // background-position: calc(100% - 10px) calc(100% - 16px);
        background-size: 12px;
        outline: none;
        box-shadow: 0px 0px 0px;
      }

    }


  .webform-component-email{
    .element-invisible {
      position: absolute !important;
      clip: auto;
      overflow: initial;
      height: auto;
    }
  }

  .webform-component-file{
    border: dashed 2px #ccc;
  width: 100%;
  padding: 10px;
  background: #013084;
  .form-file{width: 100%;}
  }
  .form-submit{
    background: transparent;
    border: solid 1px #ccc;
    color: #e2e2e2;
    padding: 8px 20px;
    border-radius: 4px;
    line-height: 32px;
    height: 43px;
  }

  // .form-actions{
  //   margin-right: 15px;
  // }

    .form-item{
      display: inline-block;
      .form-radios{
        display: inline-block;
        margin-left: 10px;
        .option{margin: 0px 5px;}
      }
    }
    .webform-component--mr-ms-mrs{
      width: 100%;
    }
    .webform-component--full-name{
      width: 100%;
    }


    .textual .left-content{
      strong{
        font-size: 20px;
      }
    }

     input.label-active + label{
      top: .1em;
      font-size: 11px;
      color: #333;
      padding: 5px 5px 5px 10px;
    }
    .webform-client-form{margin-top: 2px;}
  }
}


@media (min-width:768px){
  .careers-wrapper{
    position: relative;
    background-color: #003087;
    padding-top: 70px;
    color: #fff;
    .banner-image{
      max-height: 350px;
      overflow: hidden;
    }
    .textual{
      // padding: 4% 10%!important;
      max-width: 1100px;
    }

    .webform-component-textfield, .webform-component-number, .webform-component-email{
      width: 100%!important;
      border: solid 1px #fff;
      position: relative;
      height: 46px;
      border-radius: 4px;
      margin-top: 0px;
      background: #fff;
      label{
        position: absolute;
        top: .7em;
        padding: 4px 5px 5px 15px;
        letter-spacing: 1px;
        color: #333;
        transition: ease .3s;
        font-weight: normal;
        font-size: 14px;
        margin-bottom: 0px;
        text-transform: uppercase;
      }
      .label-active {
        top: .1em;
        font-size: 12px;
        color: #333;
        padding: 5px 5px 5px 10px;
      }
      input[type="text"], input[type="email"]{
        background: transparent;
        border: solid 0px #ccc;
        width: 100%;
        color: #333;
        text-indent: 10px;
        margin-top: 20px;
        outline: none;
        box-shadow: 0px 0px 0px;
      }
      input[type="text"], input[type="email"], input[type="password"]{
        line-height: inherit;
        width: 100%;
        padding: 0px 0px;
        margin-top: 15px;
        text-transform: inherit;
        font-size: 16px;
        color: #333;
        height: 30px;
        outline: none;
        box-shadow: 0px 0px 0px;
      }

      select{
        border: solid 0px #f5f5f5;
        text-transform: uppercase;
        font-size: 14px;
        color: #d8d8d8;
        height: 41px;
        margin-top: 3px;
        width: 100%;
        border-radius: 0px;
        -webkit-appearance: none;
        -moz-appearance: none;
        padding: 0px 10px;
        // background-position: calc(100% - 10px) calc(100% - 16px);
        background-size: 12px;
        outline: none;
        box-shadow: 0px 0px 0px;
      }

    }


  .webform-component-email{
    .element-invisible {
      position: absolute !important;
      clip: auto;
      overflow: initial;
      height: auto;
    }
  }

  .webform-component-file{
    border: dashed 2px #ccc;
  width: 100%;
  padding: 10px;
  background: #013084;
  }
  .form-submit{
    background: transparent;
    border: solid 1px #ccc;
    color: #e2e2e2;
    padding: 8px 20px;
    border-radius: 4px;
    line-height: 32px;
    height: 43px;
    float: right;
    cursor: pointer;
    box-shadow: 0px 0px 0px;
  }

  // .form-actions{
  //   margin-right: 15px;
  // }

    .form-item{
      display: inline-block;
      .form-radios{
        display: inline-block;
        margin-left: 10px;
        .option{margin: 0px 5px;}
      }
    }
    .webform-component--mr-ms-mrs{
      width: 35%!important;
    }
    .webform-component--full-name{
      width: 61% !important;
      margin-left: 10px;
    }

    .webform-component--lsyd---lsyd---lsyd{
      width: 40%!important;
    }

    .webform-component--lsm-lkml{
      width: 54% !important;
      margin-right: 10px;
    }

    .textual .left-content{
      strong{
        font-size: 23px!important;
      font-family: 'Ktf-Regular', sans-serif;
      }
    }
  }
}


// .i18n-en{
//   .contact-us{
//     select{
//       background: url(/sites/all/themes/fh/assets/icons/down-arrow.png) no-repeat;
//       background-position: calc(100% - 10px) calc(100% - 16px);
//     }
//   }
// }
//
//
// .i18n-ar{
//   .contact-us{
//     select{
//       background: url(/sites/all/themes/fh/assets/icons/down-arrow.png) no-repeat;
//       background-position: calc(10% - 10px) calc(100% - 16px);
//     }
//   }
// }

.i18n-en{
  .careers-wrapper{
    select{
      background: url(/sites/all/themes/fh/assets/icons/down-arrow-reverse.png) no-repeat;
      background-position: calc(100% - 10px) calc(100% - 16px);
    }
  }
  .contact-us-wrapper{
    select{
      background: url(/sites/all/themes/fh/assets/icons/down-arrow.png) no-repeat;
      background-position: calc(100% - 10px) calc(100% - 16px);
    }
  }

}

.i18n-ar{
  .careers-wrapper{
    select{
      background: url(/sites/all/themes/fh/assets/icons/down-arrow-reverse.png) no-repeat;
      background-position: calc(10px - 0px) calc(100% - 16px);
    }
  }

  .contact-us-wrapper{
    select{
      background: url(/sites/all/themes/fh/assets/icons/down-arrow.png) no-repeat;
      background-position: calc(10px - 0px) calc(100% - 16px);
      padding-right: 6px !important;
    }
  }

}
