
@media (min-width: 1px){
    footer {
      background: #ffffff;
      font-size: 14px;
      padding: 0px 0px 0px;
      width: 100%;
      // height: 16vh;
      .logo img{
        max-width: 180px;
        margin-top: 5px;
        width: 100%;
        transition: all 0.4s ease-in-out;
        }

      h5{
        font-weight: 500;
        color: #333;
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 15px;
      }
      ul{
        padding-left: 0px;
        li{
          color: #9c9c9c;
          font-size: 13px;
          line-height: 24px;
          a.active {color: #8a8a8a;}
          a{color: #8a8a8a;}
        }
      }
      p{
        font-size: 13px;
        color: #666;
        padding-top: 20px;
        margin-bottom: 0px;
      }

      .social-links{
        i{
          width: 32px;
          height: 35px;
          color: #4a4a4a;
          padding: 7px 0px 0px 10px;
          border-radius: 50%;
          font-size: 20px;
          margin: 1px 0px 10px 0px;
          border: solid 0px #c5c5c5;
          text-align: left;
        }
      }

      .footer-menu{
        h2{display: none;}
        ul.menu {
        text-align: center;
        }
        ul.menu li {
        display: inline;
        }
      }

    }

    .quick-info{
      background: #f5f5f5;
      display: inline-block;
      width: 100%;
      margin-bottom: 30px;
      padding: 10px;
        h6{
          font-size: 14px;
              text-transform: uppercase;
              margin-top: 30px;
        }
    }

    .copyright-dec{
      padding: 10px 0px 5px;
      border-top: solid 1px #ccc;
      p{
        padding: 10px 0px;
        margin-bottom: 0px;
        }
    }



    footer {
      // height: 16vh;
      .logo img{
        max-width: 180px;
        margin-top: 5px;
        width: 100%;
        transition: all 0.4s ease-in-out;
        }

      h5{
        font-weight: 500;
        color: #333;
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 15px;
      }
      ul{
        padding-left: 0px;
        li{
          color: #9c9c9c;
          font-size: 13px;
          line-height: 24px;
          a.active {color: #8a8a8a;}
          a{color: #8a8a8a;}
        }
      }

      .social-links{
        i{
          width: 32px;
          height: 35px;
          color: #4a4a4a;
          padding: 7px 0px 0px 10px;
          border-radius: 50%;
          font-size: 20px;
          margin: 1px 0px 10px 0px;
          border: solid 0px #c5c5c5;
          text-align: left;
        }
      }

      .footer-menu{
        h2{display: none;}
        ul.menu {
        text-align: center;
        }
        ul.menu li {
        display: inline;
        }
      }

    }

    .quick-info{
      background: #f5f5f5;
      display: inline-block;
      width: 100%;
      margin-bottom: 30px;
      padding: 10px;
        h6{
          font-size: 14px;
              text-transform: uppercase;
              margin-top: 30px;
        }
    }

    .copyright-dec{
      padding: 10px 0px 5px;
      border-top: solid 1px #ccc;
      p{
        padding: 10px 0px;
        margin-bottom: 0px;
        }
    }

}

@media (min-width: 992px){
    footer {
      background: #ffffff;
      font-size: 14px;
      width: 100%;
      // height: 16vh;
      .logo img{
        max-width: 180px;
        margin-top: 5px;
        width: 100%;
        transition: all 0.4s ease-in-out;
        }

      h5{
        font-weight: 500;
        color: #333;
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 15px;
      }
      ul{
        padding-left: 0px;
        li{
          color: #9c9c9c;
          font-size: 13px;
          line-height: 24px;
          a.active {color: #8a8a8a;}
          a{color: #8a8a8a;}
        }
      }

      .social-links{
        i{
          width: 32px;
          height: 35px;
          color: #4a4a4a;
          padding: 7px 0px 0px 10px;
          border-radius: 50%;
          font-size: 20px;
          margin: 1px 0px 10px 0px;
          border: solid 0px #c5c5c5;
          text-align: left;
        }
      }

      .footer-menu{
        h2{display: none;}
        ul.menu {
        text-align: left!important;
        }
        ul.menu li {
        display: inline;
        }
      }

    }

    .quick-info{
      background: #f5f5f5;
      display: inline-block;
      width: 100%;
      margin-bottom: 30px;
      padding: 10px;
        h6{
          font-size: 14px;
              text-transform: uppercase;
              margin-top: 30px;
        }
    }

    .copyright-dec{
      padding: 10px 0px 5px;
      border-top: solid 1px #ccc;
      p{
        padding: 10px 0px;
        margin-bottom: 0px;
        }
    }

    .quick-info{
      background: #f5f5f5;
      display: inline-block;
      width: 100%;
      margin-bottom: 30px;
      padding: 10px;
        h6{
          font-size: 14px;
              text-transform: uppercase;
              margin-top: 30px;
        }
    }

    .copyright-dec{
      padding: 10px 0px 5px;
      border-top: solid 1px #ccc;
      p{
        padding: 10px 0px;
        margin-bottom: 0px;
        }
    }
    .i18n-en{
      .social-links{text-align: right !important;}
      .copyright-text{text-align: left!important;}
    }

    .i18n-ar{
      .social-links{text-align: left !important;}
      .copyright-text{text-align: right!important;}
    }

}
