.i18n-en .openNav{
  cursor: pointer;
  float: left;
  text-transform: uppercase;
  height: 18px;
}

// .side-nav {
//     height: 100%;
//     overflow: scroll;
//     padding-bottom: 100px !important;
// }

.main-menu{
.dropdown-container{
  .dropdown-menu {
    top: 112%;
    left: 0px;
    border: 1px solid #e8e6e6;
    border-radius: 0px;
    width: 100%;
    max-width: 1160px;
    height: 300px;
    transition: all .3s ease-out;
    box-shadow: 0 0 8px rgba(0,0,0,0.1);
    ul{
      padding-left: 0px;
      li{
        font-size: 13px;
        text-transform: none;
        color: #999;
        a{
          color: #999;
        }
      }
    }
    }


    .dropdown{
      &:hover{
        .dropdown-menu{
        display: block;
        }
      }
    }
  }
}



.fa-navicon:before, .fa-reorder:before, .fa-bars:before {
    content: "\f0c9";
    font-family: FontAwesome;
    // font-size: 16px;
    // line-height: 20px;
    margin: 0px 5px;
}

.fa-remove:before, .fa-close:before, .fa-times:before {
    content: "\f00d";
    font-family: FontAwesome;
    // font-size: 16px;
    // line-height: 13px;
    margin: 0px 5px;
}

.open-menu{width: 100%;}

.navbar-toggler-icon {
    background-image: url(../../assets/icons/menu-btn.png)!important;
    background-size: initial!important;
    margin-top: 2px;
}

.overlay {
  position: fixed;
  z-index: 10;
  top: 70px;
  background: rgba(1, 13, 58, 0.9);
  transition: 0.5s;
  max-width: 320px;
  width: 0;
  height: 100%;
}

.on{
  width: 100% !important;
}

  .i18n-en{
    .on{
      left: 0px!important;
      }
    .overlay {
      left: -300px;
      }
      .openNav .toggle{
        padding-left: 25px;
        line-height: 25px;
        font-family: "Ktf-Regular",sans-serif !important;
      }

      .fabars{
        background: url(../../assets/icons/menu-icon.png) 1px 5px;
        background-repeat: no-repeat;
      }

      .fatimes{
        background: url(../../assets/icons/menu-close.png) 1px 4px;
        background-repeat: no-repeat;
      }
  }

  .i18n-ar{
    .on{
      right: 0px!important;
      }
      .side-nav{
        position: fixed;
        right: -300px;
      }

      .openNav .toggle{
        padding-right: 25px;
        line-height: 25px;
        font-family: "Ktf-Regular",sans-serif !important;
      }

      .fabars{
        background: url(../../assets/icons/menu-icon.png) right 5px;
        background-repeat: no-repeat;
      }

      .fatimes{
        background: url(../../assets/icons/menu-close.png) right 4px;
        background-repeat: no-repeat;
      }
  }




.overlay-content{
  .nav-tabs {
    border-bottom: 0px solid #efeeee;
    .nav-item {
    margin-bottom: -1px;
    width: 50%;
    }
    .nav-link {
    border: 0px solid transparent;
    border-top-right-radius: .25rem;
    border-top-left-radius: .25rem;
    }
  }
  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #464a4c;
    background-color: #fff;
    border-color: #ddd #ddd #fff;
    border-bottom: solid 3px #1eb6ff;
  }
  .tabpanels{
    overflow-y: scroll;
    height: 100%;
    .tab-content{
    overflow-y: scroll;
    height: 100%;
  .tab-pane{
        padding-top: 10px;
        height: 100%;
    .navbar-nav{
      .nav-item{
        border-bottom: dashed 1px #ccc;
        font-size: 16px;
        line-height: 40px;
      }
    }
  }
}
}
  h2{display: none;}
.menu{
  padding: 50px 0px 0px;
  li{
    list-style-image: url(.../../misc/menu-leaf.png);
    list-style-type: none;
    position: relative;
    a{
      padding: 5px 0px;
      text-transform: initial;
      text-decoration: none;
      display: block;
      transition: 0.3s;
      color: #e8dfdf;
    }
  }
  h5{
    padding-left: 30px;
    font-size: 20px;
    margin-top: 5px;
  }
  .weekPlus{
    font-size: 30px;
    font-weight: bold;
    padding-left: 0px;
    cursor: pointer;
    position: absolute;
    color: #d6ba44;
    width: 20px;
    text-align: center;
    top: -5px;
  }

}
}

.overlay a:hover, .overlay a:focus {
    color: #f1f1f1;
}

.overlay .closebtn {
  font-size: 30px;
  top: 6px;
  right: 0px;
  line-height: 25px;
  color: #a2a2a2 !important;
  height: 35px;
  position: absolute;
  width: 40px;
  text-align: center;
  z-index: 100;
}

.first-parent{
}

.i18n-en .second-parent{
      padding-left: 45px;
  li{
    position: relative;
    padding-left: 10px;
    .adMinus{
      top: 0px;
      left: -20px;
      font-size: 22px;
      font-weight: bold;
      padding-left: 0px;
      cursor: pointer;
      position: absolute;
      color: #d6ba44;
      width: 20px;
      text-align: center;
    }
  }
}

.i18n-ar .second-parent{
      padding-right: 45px;
  li{
    position: relative;
    padding-left: 10px;
    .adMinus{
      top: 0px;
      right: -20px;
      font-size: 22px;
      font-weight: bold;
      padding-right: 0px;
      cursor: pointer;
      position: absolute;
      color: #d6ba44;
      width: 20px;
      text-align: center;
    }
  }
}

.toggle {
  color: #333;
  cursor: pointer;
  font-size: 14px!important;
}

.side-nav {
  color: #fff;
  position: fixed;
  top: 60px;
  width: 320px;
  height: 100%;
  padding: 10px;
  background: rgba(1, 13, 58, 0.9);
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    a {
      padding: 0px 40px;
      display: block;
      color: rgba(255, 255, 255, 0.8);
      text-decoration: none;

      &:hover {
          color: rgba(255, 255, 255, 1);
      }
    }
  }

}

.i18n-en{
  .side-nav .mobile{
    .menu{
    padding: 0px 0px 10px 30px;
    li{    font-size: 20px; padding: 0px;}
    }
  }
}
.i18n-ar{
  .side-nav .mobile{
    .menu{
    padding: 0px 30px 10px 10px;
    li{  font-size: 20px; padding: 0px;}
    }
  }
}

.i18n-en{
  .wrapper, .side-nav {
    transition: left .9s ease;
  }

  .menu{
  text-align: left;
  h5{
    padding-left: 30px;
  }
  }

  .side-nav {
    left: -340px;
  }
  .nav-open {
    .side-nav {
      left: 0;
    }
  }
  .toggle {
    margin: 0px 6px 0px 0px;
  }

}
.i18n-ar{
  .wrapper, .side-nav {
    transition: right .9s ease;
  }

  .menu{
  text-align: right;
  padding-right: 0px;
  h5{
    padding-right: 30px;
  }
  }
  .side-nav {
    right: -340px;
  }

  .nav-open {
    .side-nav {
      right: 0;
    }
  }

  .toggle {
    margin: 0px 0px 0px 10px;
  }

}



.menuwrapper{
  height: 100% !important;
  margin: 0;
  overflow: hidden;
  margin-top: 2px;
  }

@media (min-width: 1px) and (max-width: 768px) {

  .side-nav{
    height: 100vh;
    overflow: scroll;
    .mobile{
      ul{
        padding-bottom: 100px;
      }
    }
  }

  .i18n-ar{
    .menuwrapper{
      padding-left: 0px;
      padding-right: 10px;
    }

    .openNav{
      cursor: pointer;
      float: right;
      margin-left: 0px;
      display: flex;
    }
  }

}

@media (min-width:992px){
  .i18n-ar{
    .openNav{
      cursor: pointer;
      float: right;
      margin-left: 20px;
      display: flex;
    }
  }
}

// .toggle {
//   color: #333;
//   cursor: pointer;
//   font-size: 2em;
//   position: relative;
//   top: 20px;
//   left: 20px;
// }
//
// .toggle-bar,
// .toggle-bar::before,
// .toggle-bar::after,
// .toggle-wrap.active .toggle-bar,
// .toggle-wrap.active .toggle-bar::before,
// .toggle-wrap.active .toggle-bar::after {
// 	transition: all .2s ease;
// }
// .toggle-bar {
// 	width: 28px;
// 	margin: 10px 0;
// 	position: relative;
// 	border-top: 3px solid #303030;
// 	display: inline-block;
//   cursor: pointer;
// }
// .toggle-bar::before,
// .toggle-bar::after {
//   content: "";
//   display: block;
//   background: #303030;
//   height: 3px;
//   width: 28px;
//   position: absolute;
//   top: -10px;
//   transform: rotate(0deg);
//   transform-origin: 13%;
// }
// .toggle-bar::after {
//   top: 4px;
// }
// .nav-open .toggle-bar {
//   border-top: 2px solid transparent;
// }
// .nav-open .toggle-bar::before {
// 	transform: rotate(45deg);
// }
// .nav-open .toggle-bar::after {
// 	transform: rotate(-45deg);
// }
//
// .menu-bar:hover .toggle-bar{
//   width: 22px;
//   left: 3px;
// }
//
// .menu-bar:hover .toggle-bar::before {
//   left: -3px;
//   margin-top: -2px;
// }
// .menu-bar:hover .toggle-bar::after {
// 	left: -3px;
//   margin-top: 2px;
// }
//
// .menu-bar{
//   width: 28px;
//   height: 22px;
//   overflow: hidden;
//   float: left;
//   cursor: pointer;
// }
