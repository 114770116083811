// i18n-ar{
//   .form-submit{
//     font-size: 15px;
//     font-weight: 600;
//     text-align: center;
//     text-transform: uppercase;
//     border-radius: 4px;
//     letter-spacing: 2px;
//   }
// }
body.i18n-ar{
    letter-spacing: 0px!important;
    h1, h2, h3, h4, h5, h6, a, ul, li{letter-spacing: 0px!important;}
*{letter-spacing: 0px!important;}
}


.form-submit{
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  border-radius: 4px;
  letter-spacing: 2px;
}

.blue{
  background: #1f71e2;
  border-color: #1f71e2;
  color: #fff;
  padding: 4px 20px 0px;
  border-radius: 40px;
  line-height: 40px;
  display: inline-block;
}


div.messages {
  position: relative;
  padding: 40px 10px 5px;
  padding-left: 35px;
  margin: 30px 0px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);
  background: #BBB;
  color: #FFF;
  transition: all .5s ease;
  text-align: center;
  border-color: #003087;
}
div.messages:hover{
  box-shadow: 0 15px 20px rgba(10,0,10,.3);
  -webkit-filter: brightness(110%);
}

div.messages:before{
  content: '';
  font-family: FontAwesome;
  position: absolute;
  display: block;
  top: -21px;
  left: 50%;
  margin:0 -21px;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  width: 48px;
  padding:10px;
  background: inherit;
  box-shadow:0 5px 10px rgba(0,0,0,.25);
  color: rgba(255,255,255,.75);
  border-radius:50%;
  border: 2px solid transparent;
  z-index: 2;
}

div.messages.information:before{content:'\f129';}
div.messages.announcement:before{content:'\f0f3';}
div.messages.status:before{content:'\f00c';}
div.messages.warning:before{content:'\f12a';}
div.messages.error:before{content:'\f00d';}

div.messages.information{background: #39B;}
div.messages.warning{background: #E74;}
div.messages.status{background: #0e5ae0;}
div.messages.announcement{background: #EA0;}
div.messages.error{background: #C43;}

@media (min-width:1px){
    .blue{
      background: #1f71e2;
      border-color:#1f71e2;
      color: #fff;
      padding: 4px 20px 0px;
      border-radius: 40px;
      line-height: 30px;
      display: inline-block;
      cursor: pointer;
    }

}

@media (min-width:768px){
    .blue{
      line-height: 40px!important;
    }
}

select{
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: none;
}

select::-ms-expand {
  display: none;
}


@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
 {
   body.i18n-ar{
     .explain-btn .abtadMinus {
     width: 30px;
     height: 26px;
    }
   }
 }
