.turqoise{

  .carousel-caption {
    position: absolute;
    right: 36%;
    bottom: inherit;
    top: 40%;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: left;
  }

  picture{
    position: relative;
    width: 100%;
    height: 100%;
    display: inline-block;
    img{width: 100%;}
  &::after {
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.6);
  }
  }

  .subtitle{
    font-size: 16px;
    color: #ffd800;
  }

}
