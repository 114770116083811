
@media (min-width: 1px){
  .news-letter{
    text-align: center;
    margin: auto;
    max-width: 750px;
    width: 100%;
    // height: 80vh;
    display: flex;
    align-items: center;
    h2{    font-size: 1.2rem;}
    h5, .h5 {
    margin-top: 20px;
    text-transform: uppercase;
    }
    .form-newsletter{
      h2{
        display: none;
      }
    input{
      width: 100%;
          height: 36px;
          background: transparent;
          border: 0px;
          color: #fff;
          margin-top: 0px;
          padding: 0px 10px;
    }
    input::placeholder {
    color: #ccc;
    }
  .region-fhnewsletter{width: 100%;}

  .mailchimp-newsletter-mergefields{
    width: 60%;
    height: 43px;
    float: left;
  }

  .form-actions{
    width: 38%;
    display: inline-block;
    margin-top: 16px;
  }
    .btn{

        background: #fff;
        border: solid 1px #fff;
        color: #949494;
    }

    }

  .mailchimp-signup-subscribe-form{
    border: solid 1px #9a9a9a;
    padding: 6px 0px;
    border-radius: 4px;
    margin-top: 10px;
    margin-bottom: 15px;
    .form-submit{
    background: #f9f9f9;
    color: #afafaf;
    border-radius: 2px;
    line-height: 40px;
    }

    .form-item, .form-actions {
      margin-top: 0em;
      margin-bottom: 0em;
    }
  }
p{display: none;}
  }
}


@media (min-width: 992px){
  .news-letter{
    h2{font-size: 2rem!important;}
    h5, .h5 {
    margin-top: 0px!important;
    }
    .form-newsletter{
      h2{
        display: none;
      }

  .mailchimp-newsletter-mergefields{
    width: 75%!important;
  }

  .form-actions{
    width: 23%!important;
  }
    }
    p{display: block!important;}
  }
}
