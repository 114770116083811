// .news-section-wrapper{
// position: relative;

@media (min-width: 1px){
.mobile{display: block;
      width: 100%;
      .slide{
        text-align: center;
        width: 100%;
        .carousel-inner{
          height: 110px;
          .category-item {
            margin: auto;
          }
        }
      }
}
.web{display: none;}
  .news-section{
    // top: 18%;
    position: absolute;
    width: 100%;
    padding-left: 5%;
    padding-right: 6%;
    color: #fff;
    left: 0px;
    height: 100%;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.5));
    display: flex;
    align-items: center;
    justify-content: center;
  .news-content{
    .field-date{
      color: #ffd800;
      font-weight: 600;
      }
    }

    h2 {
      margin-bottom: 1vh;
      font-size: 1.6rem;
      line-height: 1;
      font-weight: 600;
      }

    h6{
      font-size: 1rem;
      color: #fff;
      letter-spacing: 2px;
      padding-bottom: 10px;
      font-weight: 600;
    }

    .subtitle{
      font-size: 20px;
      display: block;
      color: rgba(255, 255, 255, 0.9);
    }

    h5{
      a{
        display: inline-block;
        margin-top: 0px;
      }
    }
  }
  picture{
    position: relative;
    width: 100%;
    height: 100%;
    display: inline-block;
    img{width: 100%;}
    &::after {
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 0;
      right: 0px;
      background-color: rgba(0, 0, 0, 0);
    }
  }

  .explain-btn{
    width: 24px;
    height: 24px;
    display: flex!important;
    justify-content: center;
    border-color: #ccc !important;
    padding: 0px !important;
    border-radius: 50px;
    line-height: inherit!important;
    display: inline-block;
    text-align: center;
    color: #fff;
    letter-spacing: 1px;
    background: transparent !important;
    .abtadMinus{
      width: 30px;
      display: flex;
      height: 32px;
      align-items: center;
      justify-content: center;
    }
  }
  .i18n-ar{
    .news-section{text-align: right;}
  }

  .field-quick-info{
    a{color: #fff;}
  }
}


@media (min-width: 768px){
  .mobile{display: none!important;}
  .web{
    display: flex!important;
    align-items: center;
    width: 100%;
  }
  .news-section{
    top: 0%!important;
    // padding-top: 15%;
    // padding-left: 15%!important;
  .news-content{
    .field-quick-info{}
    .field-date{
      font-size: 18px!important;
        color: #fff;
        font-weight: 600;
        margin-bottom: 20px;
        }
    }


    h2 {
      margin-bottom: 3vh;
      font-size: 2.8rem!important;
      line-height: 1.2!important;
      }

    h6{
      font-size: 1rem;
      color: #ffd800;
      letter-spacing: 2px;
      padding-bottom: 10px;
      font-weight: 600;
    }

    .subtitle{
      font-size: 20px;
      display: block;
      color: rgba(255, 255, 255, 0.9);
    }

    h5{
      a{
      margin-top: 20px!important;
      }
    }
  }
  picture{
    position: relative;
    width: 100%;
    height: 100%;
    display: inline-block;
    img{width: 100%;}
    &::after {
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 0;
      right: 0px;
      background-color: rgba(0, 0, 0, 0);
    }
  }
  .i18n-ar{
    .news-section{text-align: right; padding-right: 15%!important;}
  }
}


@media (min-width: 1px) and (max-width: 460px) {
  .field-quick-info{
    font-size: 14px;
    a{color: #fff;}
  }

  .news-content{
    .field-date{
      font-size: 14px;
      color: #ffd800;
      font-weight: 600;
      margin-bottom: 10px;
      }
    }
}

@media (min-width: 461px) and (max-width: 768px) {
  .field-quick-info{
    font-size: 17px;
    a{color: #fff;}
  }

  .news-content{
    .field-date{
      font-size: 18px;
      color: #ffd800;
      font-weight: 600;
      margin-bottom: 20px;
      }
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .manufacturing .contact-info-wrapper .content-block .logo img {
    max-width: 170px!important;
    max-height: 100px;
  }
}

.i18n-ar{
  .manufacturing .contact-info-wrapper .content-block .logo{text-align: right;}
}
