
@media (min-width:2px){
  .category-section{
    background: rgba(2, 4, 80, 0.82);
    height: 135px;
    display: flex;
    bottom: 0px;
    position: absolute;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    z-index: 1;
    a{color: #fff;}
    .category-item{
      height: 80px;
      width: 80px;
      border: solid 3px #fff;
      border-radius: 50%;
      text-align: center;
      box-shadow: 1px 1px 5px 0px #000254;
      transition: all ease 0.5s;
      &:hover{
        border: solid 3px #0f5fca;
        background: rgba(0, 109, 255, 0.17);
      }
      img{
        width: 100%;
        padding: 15px;
      }
      .catTitle{
        font-size: 15px;
        padding-top: 10px;
        display: inline-block;
        font-weight: 600;
        position: relative;
        width: 185%;
        left: -32px;
        right: -32px;
      }
    }
  }

  // .i18n-en{
  //   .category-section .category-item .catTitle{
  //       width: 185%;
  //       left: -32px;
  //       right: -15px;
  //   }
  // }
  //
  // .i18n-ar{
  //   .category-section .category-item .catTitle{
  //       width: 185%;
  //       left: -32px;
  //       right: -32px;
  //   }
  // }



  .category-inner-section{
    position: relative !important;
      background: #1a283c;
      height: 130px;
    h2 {
      padding: 40px 0px 10px;
    }
  }

  .manufacturing .category-section-title h2{
    padding: 20px 0px 30px;
  }

  .category-section-title{
    width: 100%;
    background: #1a283c;
    padding-top: 10px;
    h2{
      color: #fff;
      text-align: center;
      margin: 0px;
      padding: 0px 0px 0px;
      font-size: 1.6rem;
      }
      h5{
      color: #fee03e;
      text-align: center;
      margin: 0px;
      display: none;
      }
  }


}

@media (min-width:992px){
  .main{
  	// margin-top: 70px;
  	padding: 0px 0px;
  	min-height: 650px;
  }

  .category-section{
    background: rgba(2, 4, 80, 0.82);
    height: 145px;
    display: flex;
    bottom: 0px;
    position: absolute;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    z-index: 1;
    a{color: #fff;}
    .category-item{
      height: 80px;
      width: 80px;
      border: solid 3px #fff;
      border-radius: 50%;
      text-align: center;
      box-shadow: 1px 1px 5px 0px #000254;
      img{
        width: 100%;
        padding: 15px;
      }
      .catTitle{
        font-size: 18px!important;
      }
    }
  }


  .category-inner-section{
    position: relative !important;
      background: #1a283c;
      height: 170px!important;
    h2 {
      padding: 40px 0px 10px;
    }
  }

  .category-section-title{
    width: 100%;
    background: #1a283c;
    h2{
      color: #fff;
      text-align: center;
      margin: 0px;
      padding: 50px 0px 10px!important;
      font-size: 2rem;
      }
      h5{
      color: #fee03e;
      text-align: center;
      margin: 0px;
      display: block!important;
      }
  }

  .padding70{
    padding-top: 33% !important;
  }
}

@media (min-width: 769px) and (max-width: 1100px) {

.home-page.category-section{
  .web{padding-top: 10px;}
  }
}
@media (min-width:1100px){

    .category-section{
      padding: 10px 150px 30px;
    }
}
