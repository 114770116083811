@media (min-width:1px){
  .chairman-message{
    position: relative;
    background-color: #003087;
    padding-top: 60px;
    .textual_wrapper{
      color: #fff;
      padding-left: 8%;
      padding-right: 8%;
      padding-top: 5%;
      padding-bottom: 5%;
      background: linear-gradient(to bottom, rgba(0,0,0, 0.5), rgba(4, 50, 134, 1));
      .listview_item{
        .object_img{
        border-radius: 50%;
        overflow: hidden;
        width: 260px;
        height: 260px;
        margin-bottom: 20px;
        }

        h5{
            padding: 0px 30px;
        }
        h6, .h6 {
            font-size: .8rem;
            text-transform: uppercase;
            letter-spacing: 2px;
            margin-top: 12px;
        }
        p{
          a{color: #fff;}
        }

      }
    }
  }


  .management-team{
      position: relative;
      background-color: #003087;
      padding-top: 60px;
      background-size: 500%;
    .textual_wrapper{
      color: #fff;
      background: linear-gradient(to bottom, rgba(0,0,0, 0.5), rgba(4, 50, 134, 1));
      .listview_item{
        border-bottom: solid 1px #b7ccf3;
        padding: 10px 10px;
        margin-bottom: 0px;
        padding-top: 5%;
        padding-left: 8%;
        padding-right: 8%;
        padding-bottom: 5%;
        background: #003087;
        .object_img{
          border-radius: 50%;
          overflow: hidden;
          width: 220px;
          height: 220px;
          margin-bottom: 20px;
        }
        &:nth-child(1){
          padding-top: 5%;
          background: linear-gradient(to bottom, rgba(0,0,0,0), #003087);
        }

        h5{
            padding: 0px 30px;
            padding: 0px 30px;
            width: 100%;
            word-break: break-word;
        }
        h6, .h6 {
            font-size: .8rem;
            text-transform: uppercase;
            letter-spacing: 2px;
            margin-top: 12px;
        }
        p{
          a{color: #fff;}
        }
      }

    }
  }
}

@media (min-width:992px){
  .management-team{
    position: relative;
    background-color: #043286;
        padding-top: 70px;
        background-size: 100%!important;
  }
}
