@media (min-width:1px){
    .manufacturing{
        background: #003087;
        padding: 0px 0px 0px;
        text-align: center;
        color: #fff;
      .summary{
        padding: 6% 0% 2%;
        font-family: Ktf-Light;
        line-height: 1.5rem;
        font-size: 1.2rem;
      }
      .description{
            padding: 0% 0% 2%;
      }
      .learn-more{
        background: #e8bb00;
        color: #fff;
        border-radius: 30px;
        padding: 10px 30px;
        line-height: 30px;
        height: 45px;
        margin-bottom: 20px;
      }
      h3{
        margin-bottom: 30px;
        border-top: solid 1px #ccc;
        padding-top: 20px;
        font-size: 1.4rem;
        font-family: "Ktf-Light", Arial;
      }
      .contact-info-wrapper{
        background: #fff;
        border-radius: 20px 20px 0px 0px;
        padding: 30px 20px 20px;
        max-width: 800px;
        margin: 10px auto;
        .content-block{
          text-align: left;
          color: #333;
          font-size: 14px;
          .address-block{
            h5{
              background: url(../../assets/icons/map-icon.png) no-repeat;
              background-size: 16px;
            }
          }

          .contact-block{
            h5{
              background: url(../../assets/icons/telephone-icon.png) no-repeat;
              background-size: 15px;
            }
          }

          h5{
            color: #000000;
            text-transform: uppercase;
            font-size: 14px;
            margin-top: 0px;
            padding-left: 22px;
            font-family: "Kaff-Medium", Arial;
            line-height: 22px;
          }

          .content-field{
            color: #333;
          }
          .logo{
            img{
              max-width: 220px;
              max-height: 110px;
            }
          }
        }
      }
      .inner-banner{
        position: relative;
        .banner-image{
          img{width: 100%; object-fit: cover; transform: translate3d(0px, 0%, 0px);}
        }
        .banner-caption {
          .textual-content{
            h2, .h2 {
            font-size: 1.7rem;
            }
            h4, .h4 {
                font-size: 1.3rem;
            }
          }
        }
      }




    }

    .i18n-en .contact-info-wrapper .content-field{text-align: left;}
    .i18n-ar .contact-info-wrapper .content-field{text-align: right;}

    .i18n-ar .contact-info-wrapper .address-block h5 {
      background-position: right !important;
      padding-right: 22px;
    }

    .i18n-ar .contact-info-wrapper .contact-block h5 {
      background-position: right !important;
      padding-right: 22px;
    }
}


@media (min-width:992px){
    .manufacturing{
        background: #0260ca;
        padding: 20px 0px 0px;
        text-align: center;
        color: #fff;
      .summary{
        padding: 3% 15% 0%!important;
        line-height: 2rem!important;
        font-size: 1.3rem!important;
      }
      .description{
        padding: 2% 15% 2%!important;
      }
      .learn-more{
        background: #e8bb00;
        color: #fff;
        border-radius: 30px;
        padding: 10px 30px;
        line-height: 30px;
        height: 45px;
        margin-bottom: 20px;
      }
      h3{
        margin-top: 50px;
        padding-top: 100px!important;
        font-size: 2rem!important;
      }
      .contact-info-wrapper{
        border-radius: 60px 60px 0px 0px !important;
        padding: 30px 20px 0px !important;
        margin: auto !important;
        .content-block{
          text-align: left;
          color: #333;
          font-size: 14px;
          .address-block{
            h5{
              background: url(../../assets/icons/map-icon.png) no-repeat;
              background-size: 16px;
            }
          }

          .contact-block{
            h5{
              background: url(../../assets/icons/telephone-icon.png) no-repeat;
              background-size: 15px;
            }
          }

          h5{
            color: #000000;
            text-transform: uppercase;
            font-size: 14px;
            margin-top: 0px;
            padding-left: 22px;
            font-family: "Kaff-Medium", Arial;
            line-height: 22px;
          }

          .content-field{
            color: #333;
          }
          .logo{
            img{
                max-width: 220px;
            }
          }
        }
      }
      .banner-image {
      padding-top: 0px!important;
    }
    }
}
