body {
  font-size: 16px;
  -ms-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.6px;
  /*-webkit-font-smoothing: subpixel-antialiased;*/
}

.container{
  width: 100%;
  max-width: 6000px!important;
}

.fixed-top {
    z-index: 10;
}


.i18n-en .toggle-menu-btn{
  color: #ffd800;
  font-size: 20px;
}

.i18n-ar .toggle-menu-btn {
    color: #ffd800;
    font-size: 16px;
    margin-left: 10px;
}

header {
  .navbar-brand {
    margin-right: 0rem;
    img{max-width: 150px!important;}
    }
  .top-menu-bar {
    padding: 6px 20px 6px;
    font-size: 12px;
    color: #757575;
    background: #f3f3f3;
    i{
    font-size: 18px;
    }
    .fa-mobile{
    font-size: 20px;
    margin-right: 7px;
    }
  }

  .top-user-links-wrapper{
    padding: 15px;
    .search-block{
      border-radius: 0px;
      border: 0px solid rgba(0,0,0,0.15);
      padding: 0px 7px 0px 0px;
      height: 40px;
      overflow: hidden;
      border-bottom: 1px solid rgba(0,0,0,0.15);
    .form-inline{
      position: relative;
      .form-control {
          border: solid 0px #ccc;
          padding: 3px 45px 0px 0px;
          font-size: 14px;
          height: 37px;
          }
        .form-submit {
          background: #fff url(../../assets/icons/search-icon.png) no-repeat;
          background-position: calc(100% - 5px) calc(100% - 5px);
          background-size: 18px;
          margin-top: 0px;
          font-size: 0px;
          width: 35px;
          height: 30px;
          border: none;
          cursor: pointer;
          position: absolute;
          right: 0px;
          border-left: solid 0px #ccc;
        }
      }
    }
  }
  nav{
    background: #03a9e0;
    color: #fff;
    padding: 0.4rem 1rem !important;
    .main-menu{
      .navbar-nav{
      text-transform: uppercase;
      width: 100%;
      display: block;
      text-align: center;
      .nav-item, .dropdown-toggle{
        display: inline-block;
        padding: 0px 10px;
        }
      }
    }
  }

  .navbar-light .navbar-nav .open>.nav-link, .navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.open, .navbar-light .navbar-nav .nav-link.active {
    color: rgba(255, 255, 255, 0.9);
  }

  .navbar-toggleable-md .navbar-nav .nav-link {
      padding-right: .5rem;
      padding-left: .5rem;
      color: #fff;
      &:hover{color: #fff;}
  }

  .user-login{
    .dropdown-menu{
      top: 54px;
      right: -40px;
      left: initial;
      width: 260px;
      padding: 20px;
      border-radius: 0px !important;
      border: 1px solid #f3f3f3;
      box-shadow: 1px 3px 8px rgba(0,0,0,0.1);
      .heading{
        text-transform: uppercase;
        text-align: center;
        color: #000000;
        padding: 0px;
        margin-top: 0px;
      }
      .link{
        display: inline-block;
        width: 48% !important;
        border: solid 1px #f5f5f5;
        text-align: center;
        margin-top: 20px;
        padding: 3px 0px;
        a{ color: #00c1fd;}
      }
      }
    }
    .my-accout{
      .links{
        display: block; padding: 5px 0px;
        a{color: #00c1fd;}
            }
    }

}



@media (min-width: 1px){
  .search-box{
    display: none;
  }
.menu-text{font-family: 'Ktf-Regular', sans-serif!important;}

  header {
      height: 60px;
      width: 100%;
      background: #ffffff;
      color: #000;
      position: fixed;
      top: 0px;
      z-index: 100;
      box-shadow: 0px 0px 6px #d0d0d0;
      .logo{
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            max-width: 150px;
            width: 100%;
            transition: all 0.4s ease-in-out;
          }
      }
  }
  .region-search-block{
    border: solid 0px #ccc;
    height: 32px;
    padding: 0px 0px;
    margin-top: 10px;
    width: 100%;
    background: #f5f5f5;
    position: relative;
    .views-exposed-widget {
    float: none;
    padding: .0em 0em 0 0;
    input[type="text"]{
      border-radius: 0px;
      font-size: 13px;
      width: 100% !important;
      border: none;
      margin-top: 0px;
      height: 30px;
      text-indent: 8px;
      background: transparent;
    }
    }
    .views-submit-button{
      position: absolute;
      top: 0px;
      right: 0px;
    .form-submit{
      background: url(/sites/all/themes/wolfpack/assets/icons/search-icon.png) no-repeat;
      background-position: calc(100% - 5px) calc(100% - 6px);
      background-size: 18px;
      margin-top: 0px;
      font-size: 0px;
      width: 30px;
      height: 30px;
      border: none;
      cursor: pointer;
        }
      }
  }
  .right-menu{display: none;}
}

@media (min-width: 768px){
  header {
      // height: 70px!important;
      .logo img {
        max-width: 160px!important;
      }
  }
  .region-search-block{
    border: solid 1px #ccc !important;
    background: #fff !important;
    .views-exposed-widget {
    float: left;
    padding: .0em 0em 0 0;
    .form-submit{
      background: url(/sites/all/themes/wolfpack/assets/icons/search-icon.png) no-repeat;
      }
    }
  }
  .right-menu{display: block!important; text-transform: uppercase;}

}


  .change{
      position: fixed;
      box-shadow: 0px 1px 5px #e4e4e4;
      background: rgba(255, 255, 255, 0.9);
      .logo{
        img{
          width: 120px;
          }
      }
      .site-nav .nav-toggle {
      top: 18px;
      }
      .social-media-link {
      top: 25px;
      }

      .search-wrapper .search-btn {
      top: 19px;
      }
  }

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: lighter;
    line-height: 1;
    color: #737373;
}

.breadcrumb{
  ul{
    padding-left: 0px;
    display: flex;
    li{
      list-style-type: none;
    font-size: 12px;
    padding: 15px 20px 5px 0px;
    }

  }
}

@media (min-width: 1px){
  // header {padding-top: 5px;}
  .navbar-toggler-right {
    position: inherit;
    padding: 0px 0px 0px 30px;
    font-size: 26px;
    border: 0px;
    right: 20px;
  }

  .navbar-brand{
    padding-top: 0px;
    padding-bottom: 0px;
    img
    {
      max-width: 50px;
    }
  }
  .top-user-links{
    i{
      font-size: 18px;
      color: #a7a7a7;
      padding: 15px 0px 0px 15px;
      }
    .my-cart{
      position: relative;
    .no-item{
      position: absolute;
      top: -13px;
      right: -15px;
      background: #25d7ff;
      height: 20px;
      padding: 0px 5px;
      color: #ffffff;
      border: solid 1px #25d7ff;
      border-radius: 40px;
      font-size: 12px;
      z-index: 100;
    .line-item-summary .line-item-quantity {
    float: none;
    }
    .line-item-quantity-label{display: none;}
    }
    }
  }
.breadcrumb{display: none;}
}

@media (min-width: 992px){
  header {padding-top: 0px!important;
  }

  .navbar-brand{
    img{
    max-width: initial !important;
    }
  }

  .top-user-links{
    position: inherit !important;
  }
  .breadcrumb{display: block!important;}

  .search-box {display: inline-block !important;}
}



.right-menu{
  ul{
    padding-left: 0px;
    padding-right: 0px;
    align-items: center;
    display: flex;
    text-align: right;
    justify-content: flex-end;
    height: 40px;
    margin-bottom: 0px;
    li{
      display: inline;
      font-size: 14px;
      padding: 4px 0px 0px 15px;
      a.active, a{
        color: #000;
      }
    }
  }
  h2{display: none;}
}

// .search-box{}

.search-box {
  max-width: 300px;
  margin-left: 10px;
  margin-top: 0px;
  .form-actions{display: none;}
  .container-inline{
  // &:hover,
  // &:focus,
  // &:active {
  //   input[type="text"] {
  //     transition: 1s ease all;
  //     width: 260px;
  //     border-bottom: 1px solid #ccc;
  //     outline: none;
  //   }
  // }

  input[type="text"] {
    color: #26292c;
    font-size: 14px;
    line-height: 20px;
    margin-top: 0px;
    height: 18px;
    border: solid 0px #ccc;
    transition: 1s ease all;
  }
  .form-type-textfield{
    display: inline-block;
    margin: 0px;
  }
  .form-submit{
  width: 26px;
  height: 20px;
  border: 0px;
  border-radius: 30px;
  background: url(../../assets/icons/search-icon.png) no-repeat;
  background-size: 16px;
  background-position: 3px 0px;
  font-size: 0px;
  }
}

input {
outline: none;
}
input[type=text] {
-webkit-appearance: textfield;
-webkit-box-sizing: content-box;
font-family: inherit;
font-size: 100%;
}
input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
display: none;
}

input[type=text] {
background: url(../../assets/icons/search-icon.png) no-repeat 0px;
// background-position: calc(100% - 1px);
border: solid 1px #ccc;
padding: 0px 5px;
// width: 15px;
margin-left: 10px;
border-radius: 0em;
transition: all .5s;
background-size: 18px;
}
input[type=text]:focus {
width: 130px;
background-color: #fff;
border-color: #6dcff6;
border-bottom: solid 1px #ccc;
box-shadow: 1 1 1px rgba(109,207,246,.5);
padding-left: 20px;
}

input:-moz-placeholder {
color: #999;
}
input::-webkit-input-placeholder {
color: #999;
}
}

.search-box input[type=text] {
width: 0px;
padding-left: 12px;
color: transparent;
cursor: pointer;
}
.search-box input[type=text]:hover {
background-color: #fff;
}
.search-box input[type=text]:focus {
width: 120px;
padding-left: 20px;
color: #000;
background-color: #fff;
cursor: auto;
}
.search-box input:-moz-placeholder {
color: transparent;
}
.search-box input::-webkit-input-placeholder {
color: transparent;
}
