@media (min-width:1px){
  .snapping-banner{
    .item-list{
      li{
        position: relative;
        .textual{
          h6{
          text-transform: capitalize;
          font-size: 17px;
          }
        }
      }
    }
  }
}

@media (min-width:768px){
  .snapping-banner{
    .item-list{
      li{
        position: relative;
        .textual{
          h1{
            color: #fff;
            font-size: 50px;
            font-family: ktf-Semibold;
          }
          h6{
            font-size: 1rem;
            letter-spacing: 2px;
            padding-bottom: 10px;
            font-weight: 600;
          }

          .subtitle{
            font-size: 1rem;
            display: block;
            color: rgba(255, 255, 255, 0.9);
          }
        }
      }
    }
  }

  .snapping-banner{
    picture{
      position: relative;
      width: 100%;
      height: 100%;
      display: inline-block;
      img{width: 100%;}
    // &::after {
    //   content: "";
    //   display: block;
    //   height: 100%;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   z-index: 0;
    //   right: 0px;
    //   background-color: rgba(0, 0, 0, 0.4);
    // }
    }
  }

  .carousel-control-prev-icon, .carousel-control-next-icon {
      width: 45px;
      height: 45px;
  }

  .carousel-control-prev-icon {
  background-image: url(../../assets/icons/previous.png);
  }
  .carousel-control-next-icon {
  background-image: url(../../assets/icons/next.png);
  }

}




@media (min-width:768px){
  .snapping-banner{
    .item-list{
      li{
        position: relative;
        .textual{
          .subtitle{
            font-size: 1.4rem!important;
          }
        }
      }
    }
  }

  .snapping-banner{
    picture{
      position: relative;
      width: 100%;
      height: 100%;
      display: inline-block;
      img{width: 100%;}
    }
  }

  .carousel-control-prev-icon, .carousel-control-next-icon {
      width: 45px;
      height: 45px;
  }

  .carousel-control-prev-icon {
  background-image: url(../../assets/icons/previous.png);
  }
  .carousel-control-next-icon {
  background-image: url(../../assets/icons/next.png);
  }

}
