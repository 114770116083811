.key-benefits{
  background: #f4f4f4;
  color: #000;

    h4{
    margin-top: 10px;
    text-transform: uppercase;
    margin-bottom: 0px;
    }
    p{
      margin-bottom: 0px;
      color: #7b7b7b;
     }
    .icon{
      float: left;
      margin: 0px 10px 0px 30px;
    }
    .col-sm-3{
    padding: 25px 0px 20px;
    }
}

.bor:after {
  content: "";
  width: 1px;
  background-color: #d4d4d4;
  position: absolute;
  height: 80%;
  top: 10%;
  right: -10px;
}
