@media (min-width: 1px){

  .lgn-snapping-banner_wrp .site-content--frontpage,.lgn-snapping-banner_wrp .site-content--frontpage~* {
    display: block;
  }

  .site-content--frontpage .snapping-banner:not(.lgn-initializing) {
    height: 100vh;
    position: relative;
    background-color: #2e2e2e;
    margin-left: -16px;
    margin-right: -16px;
  }

  .site-content--frontpage .snapping-banner:not(.lgn-initializing)>* {
    opacity: 0;
  }

  .lgn-snapping-banner_wrp {
    overflow: hidden;
    height: 100vh;
  }

  .lgn-snapping-banner_wrp .site-content {
    display: none;
    pointer-events: none;
  }

  .lgn-snapping-banner_wrp .site-content>* {
    pointer-events: all;
  }

  .lgn-snapping-banner_wrp .ghost-spacer {
    position: relative;
    width: 100%;
    pointer-events: none;
    height: calc(100vh);
  }

  .lgn-snapping-banner_wrp .site-content .fh-color-4 .view-cta {
    margin-top: -1px;
    padding-top: 8rem;
  }

  .lgn-snapping-banner_wrp.lgn-banner_up {
    overflow-y: auto;
    overflow-x: hidden;
    height: auto;
  }

  .lgn-snapping-banner_wrp.lgn-banner_up .snapping-banner {
    opacity: 0;
  }

  html.no-scroll .site-content,html.scroll .site-content {
    display: block;
  }

  html.scroll body {
    overflow-y: auto;
    overflow-x: hidden;
  }

  html.no-scroll body {
    overflow-y: hidden;
  }

  .snapping-banner {
    overflow: hidden;
    background-color: #f2f2f2;
    position: absolute;
    top: 0;
    height: 100vh;
  }

  .snapping-banner:before {
    content: "";
    position: absolute;
    display: block;
    border: 1rem solid #fff;
    width: 5rem;
    height: 5rem;
    top: 50%;
    left: 50%;
    margin-left: -2.5rem;
    margin-top: -2.5rem;
    border-radius: 9999px;
    border-left: 1rem solid transparent;
    animation-name: lgn-spinner;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .snapping-banner.lgn-done li {
    opacity: 1;
  }

  .snapping-banner.lgn-done:before {
    display: none;
  }
  .snapping-banner .item-list{height: 100%;}

  .snapping-banner .item-list ul {
    margin-top: 0;
    padding-left: 0;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    overflow: hidden;
    width: 100vw;
    height: 100%;
    position: relative;
  }

  .snapping-banner .item-list li {
    transition: opacity 1s;
    width: 100vw;
    display: block;
    min-height: 100%;
    position: relative;
    overflow: hidden;
    margin-left: 0px;
    height: 100%;
    margin: 0px;
  }

  .snapping-banner .item-list li .lgn-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  html.object-fit .snapping-banner .item-list li .lgn-wrapper {
    display: none;
  }

  .snapping-banner .item-list li .visual {
    width: 100%;
    height: 100%;
    left: 0px;
  }

  .snapping-banner .item-list li .visual picture img.lgn-flood {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .snapping-banner .item-list li .visual video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-width: none;
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .snapping-banner .item-list li .visual[data-video-uri] {
    background-color: #1a1a1a;
  }

  .snapping-banner .item-list li .visual[data-video-uri] .lgn-wrapper {
    background-image: none!important;
  }

  .snapping-banner .item-list li .visual[data-video-uri].lgn-js_video_loadeddata {
    background-color: transparent;
    transition: background-color .3s;
    transition-delay: .3s;
  }

  .snapping-banner .item-list li .visual[data-video-uri].lgn-js_video_loadeddata video {
    opacity: 1;
    transition: opacity .3s;
  }
  .snapping-banner .item-list li .textual {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0);
  }

  .snapping-banner .item-list li .textual .textual-content {
    opacity: 0;
  }

  .snapping-banner .item-list li .textual h2 {
    margin-bottom: 3vh;
    color: #fff;
  }

  // .snapping-banner .item-list li>div {
  //   position: fixed;
  //   left: 0;
  //   right: 0;
  //   text-align: left;
  // }

  .snapping-banner .item-list li>div>* {
    color: #fff;
  }

  .snapping-banner .item-list li .textual {
    padding-left: 5%;
    padding-right: 5%;
    left: 0px;
  }

  .snapping-banner .item-list li .textual.fh-color-1 .highlight:before {
    background-color: #eb8024;
  }

  .snapping-banner .item-list li .textual.fh-color-2 .highlight:before {
    background-color: #0c2aa2;
  }

  .snapping-banner .item-list li .textual.fh-color-3 .highlight:before {
    background-color: #0c2aa2
  }

  .snapping-banner .item-list li .textual.fh-color-4 .highlight:before {
    background-color: #0c2aa2;
  }

  .snapping-banner .item-list li .textual.fh-color-5 .highlight:before {
    background-color: #0c2aa2;
  }
  .i18n-en{
    .snapping-banner .item-list li .textual>* {
      text-align: left;
    }
  }

  .i18n-ar{
    .snapping-banner .item-list li .textual>* {
      text-align: right;
    }
  }


  .snapping-banner .item-list li .textual .subtitle {
    display: block;
    margin-bottom: 20px;
    line-height: 20px;
  }

  .case-study-header-bottom .button .snapping-banner .item-list li .textual a,.cta-block .button .snapping-banner .item-list li .textual a,.snapping-banner .item-list li .textual .button-primary,.snapping-banner .item-list li .textual .case-study-header-bottom .button a,.snapping-banner .item-list li .textual .cta-block .button a {
    display: inline-block;
    padding: 4px 20px 0px;
    line-height: 30px;
        margin-bottom: 20px;
  }

  .snapping-banner .item-list li>.lgn-bg img {
    max-width: none;
  }

  .snapping-banner .item-list li~li {
    overflow: hidden;
    padding: 146.98095px 0;
    min-height: calc(100vh + 293.96191px);
    margin-top: 0;
    transform: translateY(0);
    position: absolute;
  }

  .snapping-banner .item-list li~.lgn-topped>.lgn-bg,.snapping-banner .item-list li~.lgn-topped>.textual {
    position: fixed!important;
  }

  .snapping-banner .item-list li~.lgn-topped>.lgn-bg {
    top: -146.98095px;
  }

  .snapping-banner .item-list li~.lgn-topped>.textual {
    top: 0!important;
  }

  .snapping-banner h2 {
    font-size: 1.4rem;
    line-height: 1;
    font-weight: 600;
  }

  .snapping-banner h2 .highlight {
    display: inline-block;
    font-style: inherit;
  }

  .snapping-banner .lgn-bg {
    top: 0;
    position: relative;
    overflow: hidden;
  }

  .snapping-banner .lgn-bg .lgn-flood {
    position: absolute;
    display: block;
    width: auto;
    height: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .snapping-banner .lgn-mask {
    display: block;
    position: absolute;
    width: auto;
    height: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    opacity: .3;
  }

  .snapping-banner .lgn-mark {
    position: absolute;
    top: 0;
    display: block;
  }

  .snapping-banner li~li .lgn-mark {
    top: 146.98095px;
  }

  .snapping-banner.lgn-topped_all .lgn-topped .lgn-ank,.snapping-banner.lgn-topped_all .lgn-topped .lgn-bg,.snapping-banner.lgn-topped_all .lgn-topped .lgn-mask {
    position: absolute!important;
  }

  .snapping-banner .highlight {
    position: relative;
    z-index: 1;
  }

  .snapping-banner .highlight:before {
    content: "";
    position: absolute;
    z-index: -1;
    z-index: -1;
    bottom: 0.6rem;
    right: 0;
    left: 0;
    height: .3rem;
    background-color: #fff;
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition: transform .5s cubic-bezier(.2,.005,.2,1) .25s;
  }

  .snapping-banner .highlight .lgn-char {
    position: relative;
  }

  .snapping-banner .highlight .lgn-char:after {
    content: "";
    display: block;
    position: absolute;
    background-color: #fb4200;
    width: 0;
    top: auto;
    right: auto;
    left: 0;
    height: .25rem;
    transition: width .12s linear,right .12s linear;
  }

  .snapping-banner .highlight .lgn-char.lgn-revealed:after {
    width: auto;
    right: 0;
  }

  .snapping-banner .highlight .lgn-char.lgn-footy:after {
    background-color: transparent;
  }

  .snapping-banner .highlight.lgn-done:before,.snapping-banner .lgn-topped .highlight:before {
    transform: scaleX(1);
  }

  .snapping-banner~* {
    position: relative;
  }

  .snapping-banner~* .lgn-mark {
    position: absolute;
    top: 0;
    display: block;
  }

  .snapping-banner+.site-content>:first-child .cta-block .text {
    padding-top: 51px;
  }

  .i18n-en .snapping-banner ul.slide-nav {
    right: .5rem;
  }

  .i18n-ar .snapping-banner ul.slide-nav {
    left: .5rem;
  }
  .snapping-banner ul.slide-nav {
    position: absolute;
    bottom: 46vh;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 0;
    list-style-type: none;
    z-index: 10;
  }

  .snapping-banner ul.slide-nav li {
    border-radius: 0%;
    cursor: pointer;
    list-style-type: none;
    width: 15px;
    height: 2px;
    transition: background 0.3s cubic-bezier(0.5, 0, 1, 0.5);
    background: rgba(255,255,255,0.5);
  }

  .snapping-banner ul.slide-nav li.active,.snapping-banner ul.slide-nav li:hover {
    background: #fff;
    width: 25px;
    height: 2px;
  }

  .snapping-banner ul.slide-nav li+li {
    margin-top: 10px;
  }

  .site-wrap{
    height: 100vh;
  }

  .last-section{
    background: linear-gradient(to right, #0098b1, #004d6b);
    color: #fff;
    display: flex!important;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100vh;
    position: relative;
    .visual{width: 100%; height: 100vh;}
  }

  .scroll_hint{display: none;}
  .blue-bg{
    background: #1a293c;
    color: #fff;
    height: 100vh;
    }

    .snapping-banner ul.slide-nav {opacity: 0;}

}



@media (min-width:768px){
    .snapping-banner h2 {
      font-size: 2.8rem!important;
    }
    .snapping-banner .item-list li .textual .textual-content {
      max-width: 90%;
      .subtitle {
          line-height: inherit !important;
        }
    }

    .i18n-en{
      .snapping-banner .item-list li .textual {
        padding-left: 15%!important;
        padding-right: 6%!important;
        width: 100%;
      }
    }

    .i18n-ar{
      .snapping-banner .item-list li .textual {
        padding-left: 6%!important;
        padding-right: 15%!important;
        width: 100%;
      }
    }

    @media (min-width:1024px){
      .snapping-banner .item-list li .textual .textual-content {
        max-width: 60%!important;
      }
    }


    // .snapping-banner .item-list li {
    //   padding-top: 70px;
    // }
  .field-quick-info{font-size: 18px!important;}

  .pos-bottom70{
    bottom: 70px;
  }

  .blue-bg{
  height: 100vh;
  }

  .case-study-header-bottom .button .snapping-banner .item-list li .textual a,.cta-block .button .snapping-banner .item-list li .textual a,.snapping-banner .item-list li .textual .button-primary,.snapping-banner .item-list li .textual .case-study-header-bottom .button a,.snapping-banner .item-list li .textual .cta-block .button a {
    line-height: 40px !important;
  }
      .snapping-banner ul.slide-nav {opacity: 1 !important;}
}

.pos-left{left:0px; bottom: 0px;}

@media (min-width: 1px) and (max-width: 768px) {

  // .snapping-banner .item-list li .textual {
  //   padding-top: 120px;
  // }
  .snapping-banner .item-list li .textual {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0);
  }

  .home-page.category-section{
    height: 135px;
padding-top: 15px;
    .mobile{
      height: 100%;
    display: flex;
    align-items: flex-start;
    }
  }
}

.last-section{
    picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.android{
  @media (min-width: 1px) and (max-width: 768px) {
  .snapping-banner .item-list li.first {
    .home-page.category-section{
      padding-top: 5px;
      bottom: 50px;
      }
    }

  }
  @media (min-width: 769px) and (max-width: 1024px) {
  .snapping-banner .item-list li.first {
    .home-page.category-section{
      padding-top: 5px;
      bottom: 90px;
      }
    }

    .site-overview > div{
      height: 100%;
    }
  }
}
