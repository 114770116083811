@media (min-width:1px){
  .contact-banner{
      max-height: 200px;
      color: #fff;
      position: relative;
      overflow: hidden;
      height: 100%;
      margin-top: 70px;
      img{
        width: 320%;
        height: 100%;
      }
      .caption{
        position: absolute;
        top: 0px;
        display: flex;
        text-align: center;
        padding: 1% 15%;
        flex-flow: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        align-items: center;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
        .textual{
          max-width: 100%;
          h2{
            font-size: 1.4rem;
          }
          p{
            font-size: 1rem;
          }
        }
      }
  }

.contact-us-wrapper{
      background: #003087;
  .contact-us{
    padding: 15px 20px;
    color: #fff;
    .address-field-wrapper{
      margin-top: 20px;
      p{
        font-size: 1rem;
        strong {
          font-size: 1.2rem;
        }
      }
    }
    .address-details{
    padding: 20px;
    h1, .typo_h1 {
      font-weight: lighter;
    }
    p{
      font-style: normal;
      line-height: 30px;
      color: #ccc;
      }
    }

    .form-item{
      width: 100%;
      border: solid 1px #fff;
      position: relative;
      height: 46px;
      float: left;
      margin-right: 10px;
      border-radius: 4px;
      margin-top: 0px;
      label{
        position: absolute;
        top: .7em;
        padding: 5px 5px 5px 15px;
        letter-spacing: 1px;
        color: #d8d8d8;
        transition: ease .3s;
        font-weight: normal;
        font-size: 14px;
        margin-bottom: 0px;
        text-transform: uppercase;
      }
      .label-active {
        top: .1em;
        font-size: 11px;
        color: #cacaca;
        padding: 5px 5px 5px 10px;
      }
      input[type="text"], input[type="email"]{
        background: transparent;
        border: solid 0px #ccc;
        width: 100%;
        color: #fff;
        text-indent: 10px;
        margin-top: 20px;
        outline: none;
        box-shadow: 0px 0px 0px;
      }
      input[type="text"], input[type="email"], input[type="password"]{
        line-height: inherit;
        width: 100%;
        padding: 0px 0px;
        margin-top: 20px;
        text-transform: inherit;
        font-size: 16px;
        color: #ffffff!important;
        height: 23px;
        outline: none;
        box-shadow: 0px 0px 0px;
      }

      select{
        // background: url(/sites/all/themes/fh/assets/icons/down-arrow.png) no-repeat;
        border: solid 0px #f5f5f5;
        text-transform: uppercase;
        font-size: 14px;
        color: #d8d8d8;
        height: 42px;
        width: 100%;
        border-radius: 0px;
        -webkit-appearance: none;
        -moz-appearance: none;
        padding: 1px 2px 1px 10px;
        background-size: 12px;
        outline: none;
        box-shadow: 0px 0px 0px;
      }

    }
    .webform-component-country-list{
      label{display: none;}
    }

    .webform-component-textarea{
      width: 100%;
      height: 158px;
      textarea{
        background: transparent;
        border: solid 0px #ccc;
        padding: 0px 10px 0px;
        margin-top: 25px;
        color: #fff;
        outline: none;
        height: 125px;
        overflow: hidden;
        box-shadow: 0px 0px 0px;
      }
      textarea::-webkit-input-placeholder {
      color: #ccc;
      padding-top: 30px;
      }
    }

    select option {
      background: #003087;
      color: #fff;
      text-transform: capitalize;
      font-size: 13px;
  }

    .grippie{display: none;}
    .property-search select:focus, .property-search input:focus {outline:0; background: transparent;}
    .form-submit{
      background: transparent;
      border: solid 1px #ccc;
      color: #e2e2e2;
      padding: 8px 20px;
      border-radius: 4px;
      line-height: 32px;
      height: 43px;
      float: right;
      cursor: pointer;
    }
    h2{
      font-size: 1.3rem;
      margin-bottom: 20px;
      margin-top: 10px;
    }

    .map{
      padding-bottom: 30px;
      iframe{
      border: 0px;
      margin-top: 15px;
      margin-bottom: 25px;
      }
      .gmap-map {
        border-radius: 4px;
        width: 100%!important;
        height: 232px!important;
      }
      .gmap-popup {
        color: #003087;
      }
    }
    label {
    display: flex;
    }
    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 10px #003087 inset;
        -moz-box-shadow: 0 0 0 10px #003087 inset;
        box-shadow: 0 0 0 10px #003087 inset;
    }
    input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
      background-color: #003eab!important;
      background-image: none!important;
      box-shadow: 0px 24px 0px 0px #003087 inset!important;
      -webkit-text-fill-color: white;
    }

  }
  }

// .webform-client-form{ margin-top: -3px;}
.contact-us-wrapper{
  .webform-client-form>div{display: inline-block; width: 100%; margin-top: 11px;}
}

}

@media (min-width:768px){
  .contact-banner{
      max-height: 300px!important;
      color: #fff;
      position: relative;
      overflow: hidden;
      img{
        width: 100%!important;
      }
      .caption{
        position: absolute;
        top: 0px;
        display: flex;
        text-align: center;
        padding: 1% 15%;
        flex-flow: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        align-items: center;
        background: linear-gradient(to bottom, rgba(255, 0, 0, 0), rgba(0, 0, 0, 0.4));
        h2{
          font-size: 2.4rem !important;
        }
        p{
          font-size: 1.4rem !important;
        }
      }
  }


  .contact-us{
    padding: 40px 5% !important;
    max-width: 1100px;
    margin: auto;
    .address-field-wrapper {
    height: 320px;
    }
    .webform-component-textarea{
      width: 97.6%!important;
    }
    .webform-component-textfield, .webform-component-number, .webform-component-select, .webform-component-email{
      width: 48%!important;
    }
    .form-submit{
      margin-right: 15px;
    }
    .address2{
      margin-top: 30px;
    }
    .map{
      padding-right: 15px;
      padding-top: 42px;
      padding-bottom: 30px;
      iframe{
      border: 0px;
      margin-top: 45px!important;
      margin-bottom: 1px!important;
      }
    }
  }

}
