@media (min-width:1px){
  .search-result{
    background-color: #003087;
    color: #fff;
    padding: 90px 5% 0px;
    .search-page__result-blocks {
    border-bottom: solid 1px #55a6da;
    color: #fff;
    margin-bottom: 20px;
    padding: 10px 0px;
    a{color: #fff;}
    .gs-title {
      color: #ffffff;
      font-size: 24px;
      overflow: hidden;
      cursor: pointer;
      a{color: #fff;}
      }
      .gsc-url-top {
      color: #ececec;
      }
      h2, .h2 {
      font-size: 1.4rem;
      }
      .description{padding: 20px 0px;}
  }

}
}


@media (min-width:992px){
  .search-result{
    padding: 90px 15% 30px!important;
    h2, .h2 {
    font-size: 2rem!important;
    }
    .field-img{
      padding: 10px 0px;
      img{
        width: auto!important;
        max-width: 650px;
      }
    }
    .description{padding: 20px!important;}
  }
}
